<template>
    <v-card outlined :max-height="maxHeight" :max-width="maxWidth">
        <v-img class="mx-2 mt-2" :src="image.path"></v-img>
        <v-card-actions>
            <v-btn
                rounded
                outlined
                x-small
                color="primary"
                @click="downLoadFile(image.path)"
            >
                <v-icon small>mdi-download</v-icon>
            </v-btn>
            <v-btn
                v-if="showDeleteBtn"
                rounded
                outlined
                @click="deleteImage"
                color="red"
                x-small
            >
                <v-icon small>mdi-delete-outline</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        image: {
            type: Object,
            default: null
        },
        onDelete: {
            type: Function,
            default: null
        },
        maxWidth: {
            type: Number,
            default: 300
        },
        maxHeight: {
            type: Number,
            default: 300
        },
        showDeleteBtn: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        deleteImage() {
            if (this.onDelete) {
                this.onDelete(this.image.nameWithDirectory);
            }
        },
        downLoadFile(url) {
            window.open(url, "_blank");
        }
    }
};
</script>

<style></style>
